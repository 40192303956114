import createWithActionCreator from "./helpers/createWithActionCreator";

//Get Cloude Connection Status
export const GET_CLOUD_CONNECTION_PENDING = "GET_CLOUD_CONNECTION_PENDING";
export const GET_CLOUD_CONNECTION_REJECTED = "GET_CLOUD_CONNECTION_REJECTED";
export const GET_CLOUD_CONNECTION_FULFILLED = "GET_CLOUD_CONNECTION_FULFILLED";
export const GET_CLOUD_CONNECTION_NEXT_PENDING =
  "GET_CLOUD_CONNECTION_NEXT_PENDING";
// Graph Range Details
export const GET_GRAPH_RANGE_DETAILS_PENDING =
  "GET_GRAPH_RANGE_DETAILS_PENDING";
export const GET_GRAPH_RANGE_DETAILS_REJECTED =
  "GET_GRAPH_RANGE_DETAILS_REJECTED";
export const GET_GRAPH_RANGE_DETAILS_FULFILLED =
  "GET_GRAPH_RANGE_DETAILS_FULFILLED";
export const GET_GRAPH_RANGE_DETAILS_RESET_PENDING =
  "GET_GRAPH_RANGE_DETAILS_RESET_PENDING";
export const GET_GRAPH_RANGE_DETAILS_RESET_FULFILLED =
  "GET_GRAPH_RANGE_DETAILS_RESET_FULFILLED";
//HNAS capacity History data
export const GET_HNAS_CAPACITY_HISTORY_DATA_PENDING =
  "GET_HNAS_CAPACITY_HISTORY_DATA_PENDING";
export const GET_HNAS_CAPACITY_HISTORY_DATA_REJECTED =
  "GET_HNAS_CAPACITY_HISTORY_DATA__REJECTED";
export const GET_HNAS_CAPACITY_HISTORY_DATA_FULFILLED =
  "GET_HNAS_CAPACITY_HISTORY_DATA_FULFILLED";
// GET HNAS TABLE DATA
export const GET_HNAS_TABLE_DATA_PENDING = "GET_HNAS_TABLE_DATA_PENDING";
export const GET_HNAS_TABLE_DATA_FULFILLED = "GET_HNAS_TABLE_DATA_FULFILLED";
// GET ASSETS
export const GET_ASSETS_PENDING = "GET_ASSETS_PENDING";
export const GET_ASSETS_REJECTED = "GET_ASSETS_REJECTED";
export const GET_ASSETS_FULFILLED = "GET_ASSETS_FULFILLED";

export const GET_RESTRICTED_ASSETS_PENDING = "GET_RESTRICTED_ASSETS_PENDING";
export const GET_RESTRICTED_ASSETS_REJECTED = "GET_RESTRICTED_ASSETS_REJECTED";
export const GET_RESTRICTED_ASSETS_FULFILLED =
  "GET_RESTRICTED_ASSETS_FULFILLED";

export const GET_REQUEST_ACCESS_PENDING = "GET_REQUEST_ACCESS_PENDING";
export const GET_REQUEST_ACCESS_REJECTED = "GET_REQUEST_ACCESS_REJECTED";
export const GET_REQUEST_ACCESS_FULFILLED = "GET_REQUEST_ACCESS_FULFILLED";
// Get permisson and partner notifications
export const GET_PERMISSION_NOTIFICATIONS_PENDING =
  "GET_PERMISSION_NOTIFICATIONS_PENDING";
export const GET_PERMISSION_NOTIFICATIONS_REJECTED =
  "GET_PERMISSION_NOTIFICATIONS_REJECTED";
export const GET_PERMISSION_NOTIFICATIONS_FULFILLED =
  "GET_PERMISSION_NOTIFICATIONS_FULFILLED";

// Get permisson and partner notifications
export const UPDATE_NOTIFICATION_PENDING = "UPDATE_NOTIFICATION_PENDING";
export const UPDATE_NOTIFICATION_REJECTED = "UPDATE_NOTIFICATION_REJECTED";
export const UPDATE_NOTIFICATION_FULFILLED = "UPDATE_NOTIFICATION_FULFILLED";

//Approve Request
export const APPROVE_REQUEST_PENDING = "APPROVE_REQUEST_PENDING";
export const APPROVE_REQUEST_REJECTED = "APPROVE_REQUEST_REJECTED";
export const APPROVE_REQUEST_FULFILLED = "APPROVE_REQUEST_FULFILLED";

//Deny Request

// Get permisson and partner notifications
export const DENY_REQUEST_PENDING = "DENY_REQUEST_PENDING";
export const DENY_REQUEST_REJECTED = "DENY_REQUEST_REJECTED";
export const DENY_REQUEST_FULFILLED = "DENY_REQUEST_FULFILLED";

export const GET_ASSETS_RESET = "GET_ASSETS_RESET";

// GET FILTERED ASSETS
export const GET_FILTERED_ASSETS_PENDING = "GET_FILTERED_ASSETS_PENDING";
export const GET_FILTERED_ASSETS_REJECTED = "GET_FILTERED_ASSETS_REJECTED";
export const GET_FILTERED_ASSETS_FULFILLED = "GET_FILTERED_ASSETS_FULFILLED";
export const GET_FILTERED_ASSETS_RESET = "GET_FILTERED_ASSETS_RESET";

// GET ACTIVE ASSETS

export const GET_ACTIVE_ASSETS_PENDING = "GET_ACTIVE_ASSETS_PENDING";
export const GET_ACTIVE_ASSETS_REJECTED = "GET_ACTIVE_ASSETS_REJECTED";
export const GET_ACTIVE_ASSETS_FULFILLED = "GET_ACTIVE_ASSETS_FULFILLED";
export const GET_ACTIVE_ASSETS_RESET = "GET_ACTIVE_ASSETS_RESET";

// GET THRESHOLD
export const GET_ASSETS_THRESHOLD_PENDING = "GET_ASSETS_THRESHOLD_PENDING";
export const GET_ASSETS_THRESHOLD_REJECTED = "GET_ASSETS_THRESHOLD_REJECTED";
export const GET_ASSETS_THRESHOLD_FULFILLED = "GET_ASSETS_THRESHOLD_FULFILLED";
export const GET_ASSETS_THRESHOLD_RESET = "GET_ASSETS_THRESHOLD_RESET";
// GET ENTITLEMENTS
export const GET_ENTITLEMENTS_PENDING = "GET_ENTITLEMENTS_PENDING";
export const GET_ENTITLEMENTS_REJECTED = "GET_ENTITLEMENTS_REJECTED";
export const GET_ENTITLEMENTS_FULFILLED = "GET_ENTITLEMENTS_FULFILLED";
export const GET_ENTITLEMENTS_RESET = "GET_ENTITLEMENTS_RESET";
// GET ASSET
export const GET_ASSET_PENDING = "GET_ASSET_PENDING";
export const GET_ASSET_REJECTED = "GET_ASSET_REJECTED";
export const GET_ASSET_FULFILLED = "GET_ASSET_FULFILLED";
export const GET_ASSET_RESET = "GET_ASSET_RESET";
// GET SELECTED ASSET
export const GET_SELECTED_ASSET_PENDING = "GET_SELECTED_ASSET_PENDING";
export const GET_SELECTED_ASSET_REJECTED = "GET_SELECTED_ASSET_REJECTED";
export const GET_SELECTED_ASSET_FULFILLED = "GET_SELECTED_ASSET_FULFILLED";
// GET ASSET_DETAILS AND GET HCP ASSET DETAILS AND HNAS ASSET DETAILS
export const GET_ASSET_DETAILS_PENDING = "GET_ASSET_DETAILS_PENDING";
export const GET_ASSET_DETAILS_REJECTED = "GET_ASSET_DETAILS_REJECTED";
export const GET_ASSET_DETAILS_FULFILLED = "GET_ASSET_DETAILS_FULFILLED";

export const GET_HCP_ASSET_DETAILS_PENDING = "GET_HCP_ASSET_DETAILS_PENDING";
export const GET_HCP_ASSET_DETAILS_REJECTED = "GET_HCP_ASSET_DETAILS_REJECTED";
export const GET_HCP_ASSET_DETAILS_FULFILLED =
  "GET_HCP_ASSET_DETAILS_FULFILLED";
export const GET_HNAS_ASSET_DETAILS_PENDING = "GET_HNAS_ASSET_DETAILS_PENDING";
export const GET_HNAS_ASSET_DETAILS_REJECTED =
  "GET_HNAS_ASSET_DETAILS_REJECTED";
export const GET_HNAS_ASSET_DETAILS_FULFILLED =
  "GET_HNAS_ASSET_DETAILS_FULFILLED";
export const GET_ASSET_DETAILS_RESET_PENDING =
  "GET_ASSET_DETAILS_RESET_PENDING";
export const GET_ASSET_DETAILS_RESET_FULFILLED =
  "GET_ASSET_DETAILS_RESET_FULFILLED";
// GET ASSET_GRAPH`
export const GET_ASSET_GRAPH_PENDING = "GET_ASSET_GRAPH_PENDING";
export const GET_ASSET_GRAPH_FULFILLED = "GET_ASSET_GRAPH_FULFILLED";
//Remove Assets
export const DELETE_REMOVE_ASSETS_PENDING = "DELETE_REMOVE_ASSETS_PENDING";
export const DELETE_REMOVE_ASSETS_REJECTED = "DELETE_REMOVE_ASSETS_REJECTED";
export const DELETE_REMOVE_ASSETS_FULFILLED = "DELETE_REMOVE_ASSETS_FULFILLED";
export const DELETE_REMOVE_ASSETS_ACTION_RESET_PENDING =
  "DELETE_REMOVE_ASSETS_ACTION_RESET_PENDING";
//Add Assets
export const CREATE_ADD_ASSETS_PENDING = "CREATE_ADD_ASSETS_PENDING";
export const CREATE_ADD_ASSETS_REJECTED = "CREATE_ADD_ASSETS_REJECTED";
export const CREATE_ADD_ASSETS_FULFILLED = "CREATE_ADD_ASSETS_FULFILLED";
export const CREATE_ADD_ASSETS_ACTION_RESET_PENDING =
  "CREATE_ADD_ASSETS_ACTION_RESET_PENDING";

//Get Approval Status
export const GET_PERFORMACE_APPROVAL_PENDING =
  "GET_PERFORMACE_APPROVAL_PENDING";
export const GET_PERFORMACE_APPROVAL_REJECTED =
  "GET_PERFORMACE_APPROVAL_REJECTED";
export const GET_PERFORMACE_APPROVAL_FULFILLED =
  "GET_PERFORMACE_APPROVAL_FULFILLED";
// GET Credentials Details

export const GET_CREDENTIALS_DETAILS_PENDING =
  "GET_CREDENTIALS_DETAILS_PENDING";
export const GET_CREDENTIALS_DETAILS_REJECTED =
  "GET_CREDENTIALS_DETAILS_REJECTED";
export const GET_CREDENTIALS_DETAILS_FULFILLED =
  "GET_CREDENTIALS_DETAILS_FULFILLED";

// GET ANALYTICS
export const GET_ANALYTICS_PENDING = "GET_ANALYTICS_PENDING";
export const GET_ANALYTICS_REJECTED = "GET_ANALYTICS_REJECTED";
export const GET_ANALYTICS_FULFILLED = "GET_ANALYTICS_FULFILLED";
// CREATE Credentials Details

export const CREATE_CREDENTIALS_DETAILS_PENDING =
  "CREATE_CREDENTIALS_DETAILS_PENDING";
export const CREATE_CREDENTIALS_DETAILS_REJECTED =
  "CREATE_CREDENTIALS_DETAILS_REJECTED";
export const CREATE_CREDENTIALS_DETAILS_FULFILLED =
  "CREATE_CREDENTIALS_DETAILS_FULFILLED";
export const GET_API_GATEWAY_INFO_PENDING = "GET_API_GATEWAY_INFO_PENDING";
export const GET_API_GATEWAY_INFO_REJECTED = "GET_API_GATEWAY_INFO_REJECTED";
export const GET_API_GATEWAY_INFO_FULFILLED = "GET_API_GATEWAY_INFO_FULFILLED";
export const UPDATE_WHITELIST_IP_PENDING = "UPDATE_WHITELIST_IP_PENDING";
export const UPDATE_WHITELIST_IP_REJECTED = "UPDATE_WHITELIST_IP_REJECTED";
export const UPDATE_WHITELIST_IP_FULFILLED = "UPDATE_WHITELIST_IP_FULFILLED";
export const UPDATE_CREATE_CREDENTIALS_STATE =
  "UPDATE_CREATE_CREDENTIALS_STATE";
// CREATE ASSET (ESCALATE)
export const CREATE_ASSET_PENDING = "CREATE_ASSET_PENDING";
export const CREATE_ASSET_REJECTED = "CREATE_ASSET_REJECTED";
export const CREATE_ASSET_FULFILLED = "CREATE_ASSET_FULFILLED";

// PATCH ASSET
export const PATCH_ASSET_PENDING = "PATCH_ASSET_PENDING";
export const PATCH_ASSET_REJECTED = "PATCH_ASSET_REJECTED";
export const PATCH_ASSET_FULFILLED = "PATCH_ASSET_FULFILLED";
// PATCH USER PREFERENCE
export const PATCH_USER_PREFERENCE_PENDING = "PATCH_USER_PREFERENCE_PENDING";
export const PATCH_USER_PREFERENCE_REJECTED = "PATCH_USER_PREFERENCE_REJECTED";
export const PATCH_USER_PREFERENCE_FULFILLED =
  "PATCH_USER_PREFERENCE_FULFILLED";
//Get Assets For Internal User
export const GET_INTUSER_ASSETS_PENDING = "GET_INTUSER_ASSETS_PENDING";
export const GET_INTUSER_ASSETS_REJECTED = "GET_INTUSER_ASSETS_REJECTED";
export const GET_INTUSER_ASSETS_FULFILLED = "GET_INTUSER_ASSETS_FULFILLED";
//Share Assets
export const CREATE_GET_ASSETS_REPORT_CSV_PENDING =
  "CREATE_GET_ASSETS_REPORT_CSV_PENDING";
export const CREATE_GET_ASSETS_REPORT_CSV_REJECTED =
  "CREATE_GET_ASSETS_REPORT_CSV_REJECTED";
export const CREATE_GET_ASSETS_REPORT_CSV_FULFILLED =
  "CREATE_GET_ASSETS_REPORT_CSV_FULFILLED";
export const CREATE_GET_ASSETS_REPORT_CSV_ACTION_RESET_PENDING =
  "CREATE_GET_ASSETS_REPORT_CSV_ACTION_RESET_PENDING";
// PATCH ASSET TO FAVORITES
export const PATCH_ASSET_TO_FAVORITES_PENDING =
  "PATCH_ASSET_TO_FAVORITES_PENDING";
export const PATCH_ASSET_TO_FAVORITES_REJECTED =
  "PATCH_ASSET_TO_FAVORITES_REJECTED";
export const PATCH_ASSET_TO_FAVORITES_FULFILLED =
  "PATCH_ASSET_TO_FAVORITES_FULFILLED";
// GET Performance Details
export const GET_PERFORMANCE_DETAILS_PENDING =
  "GET_PERFORMANCE_DETAILS_PENDING";
export const GET_PERFORMANCE_DETAILS_REJECTED =
  "GET_PERFORMANCE_DETAILS_REJECTED";
export const GET_PERFORMANCE_DETAILS_FULFILLED =
  "GET_PERFORMANCE_DETAILS_FULFILLED";
export const GET_PERFORMANCE_GRAPH_PENDING = "GET_PERFORMANCE_GRAPH_PENDING";
export const GET_PERFORMANCE_DETAILS_RESET_PENDING =
  "GET_PERFORMANCE_DETAILS_RESET_PENDING";
export const GET_PERFORMANCE_DETAILS_RESET_FULFILLED =
  "GET_PERFORMANCE_DETAILS_RESET_FULFILLED";

// GET ASSET_DETAILS AND GET HCP ASSET DETAILS AND HNAS ASSET DETAILS
export const GET_HNAS_GRAPHS_PENDING = "GET_HNAS_GRAPHS_PENDING";
export const GET_HNAS_GRAPHS_REJECTED = "GET_HNAS_GRAPHS_REJECTED";
export const GET_HNAS_GRAPHS_FULFILLED = "GET_HNAS_GRAPHS_FULFILLED";

//Get Organization Details
export const GET_ORG_DETAILS_PENDING = "GET_ORG_DETAILS_PENDING";
export const GET_ORG_DETAILS_REJECTED = "GET_ORG_DETAILS_REJECTED";
export const GET_ORG_DETAILS_FULFILLED = "GET_ORG_DETAILS_FULFILLED";

export const RESET_STATE = "RESET_STATE";

export const resetStateAction = () => ({
  type: RESET_STATE
});

//Get User Notification Actions
// Action Types
export const GET_USER_PREFERENCE_PENDING = "GET_USER_PREFERENCE_PENDING";
export const GET_USER_PREFERENCE_FULFILLED = "GET_USER_PREFERENCE_FULFILLED";
export const GET_USER_PREFERENCE_REJECTED = "GET_USER_PREFERENCE_REJECTED";

// Action Creators
export const getUserPreferencePending = () => ({
  type: GET_USER_PREFERENCE_PENDING
});

export const getUserPreferenceFulfilled = payload => ({
  type: GET_USER_PREFERENCE_FULFILLED,
  payload
});

export const getUserPreferenceRejected = error => ({
  type: GET_USER_PREFERENCE_REJECTED,
  payload: error
});

//ports actions
// GET ANALYTICS
export const GET_LUN_HOST_GROUP_DETAILS_PENDING =
  "GET_LUN_HOST_GROUP_DETAILS_PENDING";
export const GET_LUN_HOST_GROUP_DETAILS_REJECTED =
  "GET_LUN_HOST_GROUP_DETAILS_REJECTED";
export const GET_LUN_HOST_GROUP_DETAILS_FULFILLED =
  "GET_LUN_HOST_GROUP_DETAILS_FULFILLED";

export const getLunHostGroupDetailsPending = payload => ({
  type: GET_LUN_HOST_GROUP_DETAILS_PENDING,
  payload: payload
});

export const getLunHostGroupDetailsRejected = error => ({
  type: GET_LUN_HOST_GROUP_DETAILS_REJECTED,
  payload: error
});

export const getLunHostGroupDetailsFulfilled = payload => {
  return {
    type: GET_LUN_HOST_GROUP_DETAILS_FULFILLED,
    payload
  };
};

export const getActiveAssetsPending = assets => ({
  type: GET_ACTIVE_ASSETS_PENDING,
  payload: { assets }
});

export const getActiveAssetsRejected = error => ({
  type: GET_ACTIVE_ASSETS_REJECTED,
  payload: error
});

export const getActiveAssetsFulfilled = payload => {
  return {
    type: GET_ACTIVE_ASSETS_FULFILLED,
    payload
  };
};

export const getFilteredAssetsPending = (
  assets,
  entitlements,
  selectedFilterValues,
  role,
  storageOption
) => ({
  type: GET_FILTERED_ASSETS_PENDING,
  payload: { assets, entitlements, selectedFilterValues, role, storageOption }
});

export const getFilteredAssetsRejected = error => ({
  type: GET_FILTERED_ASSETS_REJECTED,
  payload: error
});

export const getFilteredAssetsFulfilled = payload => {
  return {
    type: GET_FILTERED_ASSETS_FULFILLED,
    payload
  };
};

export const getAssetsPending = params => ({
  type: GET_ASSETS_PENDING,
  payload: { params }
});

export const getAssetsRejected = error => ({
  type: GET_ASSETS_REJECTED,
  payload: error
});

export const getAssetsFulfilled = payload => {
  return {
    type: GET_ASSETS_FULFILLED,
    payload
  };
};

export const getRestrictedAssetsPending = params => ({
  type: GET_RESTRICTED_ASSETS_PENDING,
  payload: { params }
});

export const getRestrictedAssetsRejected = error => ({
  type: GET_RESTRICTED_ASSETS_REJECTED,
  payload: error
});

export const getRestrictedAssetsFulfilled = payload => {
  return {
    type: GET_RESTRICTED_ASSETS_FULFILLED,
    payload
  };
};
//Get Permission / partner  notifications

export const getPermissionNotificationsPending = params => ({
  type: GET_PERMISSION_NOTIFICATIONS_PENDING,
  payload: { params }
});

export const getPermissionNotificationsRejected = error => ({
  type: GET_PERMISSION_NOTIFICATIONS_REJECTED,
  payload: error
});

export const getPermissionNotificationsFulfilled = payload => {
  return {
    type: GET_PERMISSION_NOTIFICATIONS_FULFILLED,
    payload
  };
};

// Update Notifications
export const updateNotificationPending = params => ({
  type: UPDATE_NOTIFICATION_PENDING,
  payload: { params }
});

export const updateNotificationsRejected = error => ({
  type: UPDATE_NOTIFICATION_REJECTED,
  payload: error
});

export const updateNotificationsFulfilled = () => {
  return {
    type: UPDATE_NOTIFICATION_FULFILLED
    // payload
  };
};

//Approve Request
export const approveRequestPending = params => ({
  type: APPROVE_REQUEST_PENDING,
  payload: { params }
});

export const approveRequestRejected = error => ({
  type: APPROVE_REQUEST_REJECTED,
  payload: error
});

export const approveRequestFulfilled = payload => {
  return {
    type: APPROVE_REQUEST_FULFILLED,
    payload
  };
};

//Deny Request

//Approve Request
export const denyRequestPending = payload => ({
  type: DENY_REQUEST_PENDING,
  payload
});

export const denyRequestRejected = error => ({
  type: DENY_REQUEST_REJECTED,
  payload: error
});

export const denyRequestFulfilled = payload => {
  return {
    type: DENY_REQUEST_FULFILLED,
    payload
  };
};
export const getRequestAccessPending = params => ({
  type: GET_REQUEST_ACCESS_PENDING,
  payload: { params }
});

export const getRequestAccessRejected = error => ({
  type: GET_REQUEST_ACCESS_REJECTED,
  payload: error
});

export const getRequestAccessFulfilled = payload => {
  return {
    type: GET_REQUEST_ACCESS_FULFILLED,
    payload
  };
};

export const getAssetsReset = payload => {
  return {
    type: GET_ASSETS_RESET,
    payload
  };
};

export const getAssetsThresholdPending = params => ({
  type: GET_ASSETS_THRESHOLD_PENDING,
  payload: { params }
});

export const getAssetsThresholdRejected = error => ({
  type: GET_ASSETS_THRESHOLD_REJECTED,
  payload: error
});

export const getAssetsThresholdFulfilled = payload => {
  return {
    type: GET_ASSETS_THRESHOLD_FULFILLED,
    payload
  };
};

export const getAssetsThresholdReset = payload => {
  return {
    type: GET_ASSETS_THRESHOLD_RESET,
    payload
  };
};

export const getEntitlementsPending = params => ({
  type: GET_ENTITLEMENTS_PENDING,
  payload: { params }
});

export const getEntitlementsRejected = error => ({
  type: GET_ENTITLEMENTS_REJECTED,
  payload: error
});

export const getEntitlementsFulfilled = payload => {
  return {
    type: GET_ENTITLEMENTS_FULFILLED,
    payload
  };
};

export const getEntitlementsReset = payload => {
  return {
    type: GET_ENTITLEMENTS_RESET,
    payload
  };
};

export const getAssetPending = (
  serialNumber,
  siteId,
  productFamilyId,
  params
) => ({
  type: GET_ASSET_PENDING,
  payload: { serialNumber, siteId, productFamilyId, params }
});

export const getAssetRejected = error => ({
  type: GET_ASSET_REJECTED,
  payload: error
});

export const getAssetFulfilled = payload => {
  return {
    type: GET_ASSET_FULFILLED,
    payload
  };
};

export const getAssetReset = payload => {
  return {
    type: GET_ASSET_RESET,
    payload
  };
};

export const getSelectedAssetPending = payload => {
  return {
    type: GET_SELECTED_ASSET_PENDING,
    payload: { payload }
  };
};

export const getSelectedAssetRejected = error => ({
  type: GET_SELECTED_ASSET_REJECTED,
  payload: error
});

export const getSelectedAssetFulfilled = payload => {
  return {
    type: GET_SELECTED_ASSET_FULFILLED,
    payload
  };
};

export const getAssetDetailsPending = (
  serialNumber,
  params,
  site,
  noAccessMsg,
  site_info,
  userRole
) => ({
  type: GET_ASSET_DETAILS_PENDING,
  payload: { serialNumber, params, site, noAccessMsg, site_info, userRole }
});

export const getHCPAssetDetailsPending = (
  serialNumber,
  params,
  site,
  noAccessMsg,
  site_info
) => ({
  type: GET_HCP_ASSET_DETAILS_PENDING,
  payload: { serialNumber, params, site, noAccessMsg, site_info }
});

export const getAssetDetailsRejected = error => ({
  type: GET_ASSET_DETAILS_REJECTED,
  payload: error
});

export const getHCPAssetDetailsRejected = error => ({
  type: GET_HCP_ASSET_DETAILS_REJECTED,
  payload: error
});

export const getAssetDetailsFulfilled = payload => {
  return {
    type: GET_ASSET_DETAILS_FULFILLED,
    payload
  };
};

export const getHCPAssetDetailsFulfilled = payload => {
  return {
    type: GET_HCP_ASSET_DETAILS_FULFILLED,
    payload
  };
};

export const getHNASAssetDetailsPending = (
  serialNumber,
  params,
  site,
  noAccessMsg,
  site_info,
  userRole
) => ({
  type: GET_HNAS_ASSET_DETAILS_PENDING,
  payload: { serialNumber, params, site, noAccessMsg, site_info, userRole }
});

export const getHNASAssetDetailsRejected = error => ({
  type: GET_HNAS_ASSET_DETAILS_REJECTED,
  payload: error
});

export const getHNASAssetDetailsFulfilled = payload => {
  return {
    type: GET_HNAS_ASSET_DETAILS_FULFILLED,
    payload
  };
};

export const getAssetDetailsResetPending = payload => {
  return {
    type: GET_ASSET_DETAILS_RESET_PENDING,
    payload
  };
};

export const getAssetDetailsResetFulfilled = payload => {
  return {
    type: GET_ASSET_DETAILS_RESET_FULFILLED,
    payload
  };
};

export const getAssetGraphPending = (
  serialNumber,
  params,
  productFamily,
  site_info
) => ({
  type: GET_ASSET_GRAPH_PENDING,
  payload: { serialNumber, params, productFamily, site_info }
});

export const getAssetGraphFulfilled = (payload, graph) => {
  return {
    type: GET_ASSET_GRAPH_FULFILLED,
    payload,
    graph
  };
};

export const getHnasTableDataPending = (
  serialNumber,
  params,
  productFamily,
  site_info
) => ({
  type: GET_HNAS_TABLE_DATA_PENDING,
  payload: { serialNumber, params, productFamily, site_info }
});

export const getHnasTableDataFulfilled = (payload, graph) => {
  return {
    type: GET_HNAS_TABLE_DATA_FULFILLED,
    payload,
    graph
  };
};

export const getAnalyticsPending = params => {
  return {
    type: GET_ANALYTICS_PENDING,
    payload: { params }
  };
};

export const getAnalyticsRejected = error => ({
  type: GET_ANALYTICS_REJECTED,
  payload: error
});

export const getAnalyticsFulfilled = payload => {
  return {
    type: GET_ANALYTICS_FULFILLED,
    payload
  };
};

export const postAssetPending = asset => ({
  type: CREATE_ASSET_PENDING,
  payload: { asset }
});

export const postAssetRejected = error => ({
  type: CREATE_ASSET_REJECTED,
  payload: error
});

export const postAssetFulfilled = payload => {
  return {
    type: CREATE_ASSET_FULFILLED,
    payload
  };
};

export const patchAssetPending = (serialNumber, asset, params) => ({
  type: PATCH_ASSET_PENDING,
  payload: { serialNumber, asset, params }
});

export const patchAssetRejected = error => ({
  type: PATCH_ASSET_REJECTED,
  payload: error
});

export const patchAssetFulfilled = payload => {
  return {
    type: PATCH_ASSET_FULFILLED,
    payload
  };
};

export const patchUserPreferencePending = hideNotification => ({
  type: PATCH_USER_PREFERENCE_PENDING,
  payload: { hideNotification }
});

export const patchUserPreferenceRejected = error => ({
  type: PATCH_USER_PREFERENCE_REJECTED,
  payload: error
});

export const patchUserPreferenceFulfilled = payload => {
  return {
    type: PATCH_USER_PREFERENCE_FULFILLED,
    payload
  };
};

export const patchAssetToFavouritesPending = (
  params,
  state,
  allAssets,
  isFavoriteSelectedInInventory,
  isFavInBulk
) => ({
  type: PATCH_ASSET_TO_FAVORITES_PENDING,
  payload: {
    params,
    state,
    allAssets,
    isFavoriteSelectedInInventory,
    isFavInBulk
  }
});

export const patchAssetToFavouritesRejected = error => ({
  type: PATCH_ASSET_TO_FAVORITES_REJECTED,
  payload: error
});

export const patchAssetToFavouritesFulfilled = payload => {
  return {
    type: PATCH_ASSET_TO_FAVORITES_FULFILLED,
    payload
  };
};
export const getIntUserAssetsPending = params => ({
  type: GET_INTUSER_ASSETS_PENDING,
  payload: { params }
});

export const getIntUserAssetsRejected = error => ({
  type: GET_INTUSER_ASSETS_REJECTED,
  payload: error
});

export const getIntUserAssetsFulfilled = payload => {
  return {
    type: GET_INTUSER_ASSETS_FULFILLED,
    payload
  };
};

export const removeAssetsPending = assets => ({
  type: DELETE_REMOVE_ASSETS_PENDING,
  payload: { assets }
});

export const removeAssetsRejected = error => ({
  type: DELETE_REMOVE_ASSETS_REJECTED,
  payload: error
});

export const removeAssetsFulfilled = payload => {
  return {
    type: DELETE_REMOVE_ASSETS_FULFILLED,
    payload
  };
};

export const removeAssetsActionResetPending = payload => ({
  type: DELETE_REMOVE_ASSETS_ACTION_RESET_PENDING,
  payload: { payload }
});

export const addAssetsPending = assets => ({
  type: CREATE_ADD_ASSETS_PENDING,
  payload: { assets }
});

export const addAssetsRejected = error => ({
  type: CREATE_ADD_ASSETS_REJECTED,
  payload: error
});

export const addAssetsFulfilled = payload => {
  return {
    type: CREATE_ADD_ASSETS_FULFILLED,
    payload
  };
};

export const addAssetsActionResetPending = payload => ({
  type: CREATE_ADD_ASSETS_ACTION_RESET_PENDING,
  payload: { payload }
});

export const getAssetsReportCSVPending = assets => ({
  type: CREATE_GET_ASSETS_REPORT_CSV_PENDING,
  payload: { assets }
});

export const getAssetsReportCSVRejected = error => ({
  type: CREATE_GET_ASSETS_REPORT_CSV_REJECTED,
  payload: error
});

export const getAssetsReportCSVFulfilled = payload => {
  return {
    type: CREATE_GET_ASSETS_REPORT_CSV_FULFILLED,
    payload
  };
};

export const getAssetsReportCSVActionResetPending = payload => ({
  type: CREATE_GET_ASSETS_REPORT_CSV_ACTION_RESET_PENDING,
  payload: { payload }
});

export const getPerformanceDetailsPending = (serialNumber, params) => ({
  type: GET_PERFORMANCE_DETAILS_PENDING,
  payload: { serialNumber, params }
});

export const getPerformanceDetailsRejected = error => ({
  type: GET_PERFORMANCE_DETAILS_REJECTED,
  payload: error
});

export const getPerformanceDetailsFulfilled = payload => {
  return {
    type: GET_PERFORMANCE_DETAILS_FULFILLED,
    payload
  };
};

export const getCredentialsDetailsPending = payload => ({
  type: GET_CREDENTIALS_DETAILS_PENDING,
  payload
});

export const getCredentialsDetailsRejected = error => ({
  type: GET_CREDENTIALS_DETAILS_REJECTED,
  payload: error
});

export const getCredentialsDetailsFulfilled = payload => {
  return {
    type: GET_CREDENTIALS_DETAILS_FULFILLED,
    payload
  };
};

export const updateCreateCredentialsState = params => ({
  type: UPDATE_CREATE_CREDENTIALS_STATE,
  payload: params
});

export const createCredentialsDetailsPending = params => ({
  type: CREATE_CREDENTIALS_DETAILS_PENDING,
  payload: { params }
});

export const createCredentialsDetailsRejected = error => ({
  type: CREATE_CREDENTIALS_DETAILS_REJECTED,
  payload: error
});

export const createCredentialsDetailsFulfilled = payload => {
  return {
    type: CREATE_CREDENTIALS_DETAILS_FULFILLED,
    payload
  };
};

export const getApiGetwayInfoPending = params => ({
  type: GET_API_GATEWAY_INFO_PENDING,
  payload: { params }
});

export const getApiGetwayInfoRejected = error => ({
  type: GET_API_GATEWAY_INFO_REJECTED,
  payload: error
});

export const getApiGetwayInfoFulfilled = payload => {
  return {
    type: GET_API_GATEWAY_INFO_FULFILLED,
    payload
  };
};

export const updateWhitelistIpPending = params => ({
  type: UPDATE_WHITELIST_IP_PENDING,
  payload: { params }
});

export const updateWhitelistIpRejected = error => ({
  type: UPDATE_WHITELIST_IP_REJECTED,
  payload: error
});

export const updateWhitelistIpFulfilled = payload => {
  return {
    type: UPDATE_WHITELIST_IP_FULFILLED,
    payload
  };
};

export const getPerformanceGraphPending = (
  serialNumber,
  params,
  productFamily
) => ({
  type: GET_PERFORMANCE_GRAPH_PENDING,
  payload: { serialNumber, params, productFamily }
});

export const getPerformanceDetailsResetPending = payload => {
  return {
    type: GET_PERFORMANCE_DETAILS_RESET_PENDING,
    payload
  };
};

export const getPerformanceDetailsResetFulfilled = payload => {
  return {
    type: GET_PERFORMANCE_DETAILS_RESET_FULFILLED,
    payload
  };
};

export const getGraphRangeDetailsPending = (range, tab, graph) => ({
  type: GET_GRAPH_RANGE_DETAILS_PENDING,
  payload: { range, tab, graph }
});

export const getGraphRangeDetailsRejected = error => ({
  type: GET_GRAPH_RANGE_DETAILS_REJECTED,
  payload: error
});

export const getGraphRangeDetailsFulfilled = payload => {
  return {
    type: GET_GRAPH_RANGE_DETAILS_FULFILLED,
    payload
  };
};

export const getGraphRangeDetailsResetPending = payload => {
  return {
    type: GET_GRAPH_RANGE_DETAILS_RESET_PENDING,
    payload
  };
};

export const getGraphRangeDetailsResetFulfilled = payload => {
  return {
    type: GET_GRAPH_RANGE_DETAILS_RESET_FULFILLED,
    payload
  };
};

export const getCloudConnectionPending = (
  serialNumber,
  siteId,
  productFamilyId
) => ({
  type: GET_CLOUD_CONNECTION_PENDING,
  payload: { serialNumber, siteId, productFamilyId }
});

export const getCloudConnectionRejected = error => ({
  type: GET_CLOUD_CONNECTION_REJECTED,
  payload: error
});

export const getCloudConnectionFulfilled = payload => {
  return {
    type: GET_CLOUD_CONNECTION_FULFILLED,
    payload
  };
};

export const getCloudConnectionResetPending = payload => {
  return {
    type: GET_CLOUD_CONNECTION_NEXT_PENDING,
    payload
  };
};

export const getOrgDetailsPending = (serialNumber, siteId) => ({
  type: GET_ORG_DETAILS_PENDING,
  payload: { serialNumber, siteId }
});

export const getOrgDetailsRejected = error => ({
  type: GET_ORG_DETAILS_REJECTED,
  payload: error
});

export const getOrgDetailsFulfilled = payload => {
  return {
    type: GET_ORG_DETAILS_FULFILLED,
    payload
  };
};

export const getPerformanceApprovalsPending = (
  serialNumber,
  siteId,
  approvalStatus
) => ({
  type: GET_PERFORMACE_APPROVAL_PENDING,
  payload: { serialNumber, siteId, approvalStatus }
});

export const getPerformanceApprovalRejected = error => ({
  type: GET_PERFORMACE_APPROVAL_REJECTED,
  payload: error
});

export const getPerformanceApprovalFulfilled = payload => {
  return {
    type: GET_PERFORMACE_APPROVAL_FULFILLED,
    payload
  };
};

export const getHNASCapacityHistoryDataPending = (
  serialNumber,
  siteId,
  site_info,
  approvalStatus
) => ({
  type: GET_HNAS_CAPACITY_HISTORY_DATA_PENDING,
  payload: { serialNumber, siteId, site_info, approvalStatus }
});

export const getHNASCapacityHistoryDataRejected = error => ({
  type: GET_HNAS_CAPACITY_HISTORY_DATA_REJECTED,
  payload: error
});

export const getHNASCapacityHistoryDataFulfilled = payload => {
  return {
    type: GET_HNAS_CAPACITY_HISTORY_DATA_FULFILLED,
    payload
  };
};
export const getHNASGraphsPending = (
  serialNumber,
  params,
  site,
  noAccessMsg,
  site_info
) => ({
  type: GET_HNAS_GRAPHS_PENDING,
  payload: { serialNumber, params, site, noAccessMsg, site_info }
});

export const getHNASGraphsRejected = error => ({
  type: GET_HNAS_GRAPHS_REJECTED,
  payload: error
});

export const getHNASGraphsFulfilled = payload => {
  return {
    type: GET_HNAS_GRAPHS_FULFILLED,
    payload
  };
};

export const withGetRestrictedAssets = createWithActionCreator(
  "getRestrictedAssets",
  getRestrictedAssetsPending
);

export const withGetRequestAccess = createWithActionCreator(
  "getRequestAccess",
  getRequestAccessPending
);

export const withGetPermissionNotifications = createWithActionCreator(
  "getPermissionNotifications",
  getPermissionNotificationsPending
);

export const withApproveRequest = createWithActionCreator(
  "approveRequest",
  approveRequestPending
);

export const withDenyRequest = createWithActionCreator(
  "denyRequest",
  denyRequestPending
);

export const withUpdateNotification = createWithActionCreator(
  "updateNotification",
  updateNotificationPending
);

export const withGetUserPreference = createWithActionCreator(
  "getUserPreference",
  getUserPreferencePending
);

export const withGetLunHostGroupDetails = createWithActionCreator(
  "getLunHostGroupDetails",
  getLunHostGroupDetailsPending
);

export const withGetHNASCapacityHistoryData = createWithActionCreator(
  "getHNASCapacityHistoryData",
  getHNASCapacityHistoryDataPending
);

export const withGetFilteredAssets = createWithActionCreator(
  "getFilteredAssets",
  getFilteredAssetsPending
);

export const withGetActiveAssets = createWithActionCreator(
  "getActiveAssets",
  getActiveAssetsPending
);

export const withGetAssets = createWithActionCreator(
  "getAssets",
  getAssetsPending
);

export const withGetAssetsThreshold = createWithActionCreator(
  "getAssetsThreshold",
  getAssetsThresholdPending
);

export const withGetEntitlements = createWithActionCreator(
  "getEntitlements",
  getEntitlementsPending
);

export const withResetAssets = createWithActionCreator(
  "resetAssets",
  getAssetsReset
);

export const withResetEntitlements = createWithActionCreator(
  "resetEntitlements",
  getEntitlementsReset
);

export const withGetAsset = createWithActionCreator(
  "getAsset",
  getAssetPending
);

export const withResetAsset = createWithActionCreator(
  "resetAsset",
  getAssetReset
);

export const withGetAssetDetails = createWithActionCreator(
  "getAssetDetails",
  getAssetDetailsPending
);
export const withHNASGraphs = createWithActionCreator(
  "getAssetHNASTempAndPower",
  getHNASGraphsPending
);

export const withGetAssetDetailsReset = createWithActionCreator(
  "getAssetDetailsReset",
  getAssetDetailsResetPending
);

export const withGetHCPAssetDetails = createWithActionCreator(
  "getHCPAssetDetails",
  getHCPAssetDetailsPending
);

export const withGetHNASAssetDetails = createWithActionCreator(
  "getHNASAssetDetails",
  getHNASAssetDetailsPending
);

export const withGetPerformanceDetailsReset = createWithActionCreator(
  "getPerformanceDetailsReset",
  getPerformanceDetailsResetPending
);

export const withGetAssetGraph = createWithActionCreator(
  "getAssetGraph",
  getAssetGraphPending
);

export const withGetHnasTableData = createWithActionCreator(
  "getHnasTableData",
  getHnasTableDataPending
);

export const withGetAnalytics = createWithActionCreator(
  "getAnalytics",
  getAnalyticsPending
);

export const withGetSelectedAsset = createWithActionCreator(
  "getSelectedAsset",
  getSelectedAssetPending
);

export const withPostAsset = createWithActionCreator(
  "postAsset",
  postAssetPending
);

export const withPatchUserPreference = createWithActionCreator(
  "patchUserPreference",
  patchUserPreferencePending
);

export const withPatchAssetToFavourites = createWithActionCreator(
  "patchAssetToFavourites",
  patchAssetToFavouritesPending
);

export const withPatchAsset = createWithActionCreator(
  "patchAsset",
  patchAssetPending
);

export const withGetAssetsIntUser = createWithActionCreator(
  "getAssetsForIntUser",
  getIntUserAssetsPending
);

export const withRemoveAssets = createWithActionCreator(
  "removeAssets",
  removeAssetsPending
);

export const withAddAssets = createWithActionCreator(
  "addAssets",
  addAssetsPending
);

export const withGetAssetsReportCSV = createWithActionCreator(
  "getAssetsReportCSV",
  getAssetsReportCSVPending
);

export const withAddAssetsActionReset = createWithActionCreator(
  "addAssetsActionReset",
  addAssetsActionResetPending
);

export const withRemoveAssetsActionReset = createWithActionCreator(
  "removeAssetsActionReset",
  removeAssetsActionResetPending
);

export const withGetAssetsReportCSVActionReset = createWithActionCreator(
  "getAssetsReportCSVActionReset",
  getAssetsReportCSVActionResetPending
);

export const withGetCloudConnection = createWithActionCreator(
  "getCloudConnection",
  getCloudConnectionPending
);

export const withGetOrgDetails = createWithActionCreator(
  "getOrgDetails",
  getOrgDetailsPending
);

export const withGetCredentialsDetails = createWithActionCreator(
  "getCredentialsDetails",
  getCredentialsDetailsPending
);

export const withCreateCredentialsDetails = createWithActionCreator(
  "createCredentialsDetails",
  createCredentialsDetailsPending
);
export const withUpdateCreateCredentialsState = createWithActionCreator(
  "updateCreateCredentialsState",
  updateCreateCredentialsState
);
export const withGetApiGetwayInfo = createWithActionCreator(
  "getApiGetwayInfo",
  getApiGetwayInfoPending
);
export const withUpdateWhitelistIp = createWithActionCreator(
  "updateWhitelistIp",
  updateWhitelistIpPending
);

export const withGetPerformanceDetails = createWithActionCreator(
  "getPerformanceDetails",
  getPerformanceDetailsPending
);

export const withGetGraphRangeDetails = createWithActionCreator(
  "getGraphRangeDetails",
  getGraphRangeDetailsPending
);

export const withResetCloudConnection = createWithActionCreator(
  "resetCloudConnection",
  getCloudConnectionResetPending
);

export const withGetPerformanceApproval = createWithActionCreator(
  "getPerformanceApproval",
  getPerformanceApprovalsPending
);

export const withGetPerformanceGraph = createWithActionCreator(
  "getPerformanceGraph",
  getPerformanceGraphPending
);
